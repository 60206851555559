import { Entity, ImpactDataForCompare, Portfolio } from '@netpurpose/api'
import { NOT_FOUND } from '@netpurpose/types'
import { CompareImpactData, ComparePerformance, CompareSummaryData } from './getColumns'
import { getQueryParamsAsObjectArray } from './routing'

export const getPortfolioAndCompanyDataInCompareOrder = ({
  compareValues,
  entities,
  portfolios,
}: {
  compareValues: ReturnType<typeof getQueryParamsAsObjectArray>
  entities: Entity[] | undefined
  portfolios: Portfolio[] | undefined
}) => {
  return compareValues
    .map((value) => {
      const compareCompany = entities?.find((entity) => `${entity.id}` === value.id)
      const comparePortfolio = portfolios?.find((portfolio) => `${portfolio.id}` === value.id)

      if (value.type === 'company' && compareCompany) {
        return {
          ...compareCompany,
          dataType: 'company' as const,
          key: value.id,
        }
      }

      if (value.type === 'portfolio' && comparePortfolio) {
        return {
          ...comparePortfolio,
          dataType: 'portfolio' as const,
          key: value.id,
        }
      }

      return undefined
    })
    .filter((item): item is CompareSummaryData => item !== undefined)
}

// Once the data is in the table, if not all portfolios/companies have a value
// for a question, it could show '...' for the unit. So we need to find the
// object that does have a value and therefore the unit to show in the table.
export const getUnitName = ({
  initialUnitName,
  portfolioIds,
  portfoliosImpactData,
  entityIds,
  entitiesImpactData,
  questionIndex,
}: {
  initialUnitName: string
  portfolioIds: string[]
  portfoliosImpactData: ImpactDataForCompare | undefined
  entityIds: string[]
  entitiesImpactData: ImpactDataForCompare | undefined
  questionIndex: number
}) => {
  if (initialUnitName === NOT_FOUND) {
    const portfolioIdWithDataForQuestion = portfolioIds.find((id) => {
      const unitName = portfoliosImpactData?.[id]?.[questionIndex]?.unitName
      return unitName && unitName !== NOT_FOUND
    })

    if (portfolioIdWithDataForQuestion) {
      return (
        portfoliosImpactData?.[portfolioIdWithDataForQuestion]?.[questionIndex]?.unitName ||
        NOT_FOUND
      )
    }

    const entityIdWithDataForQuestion = entityIds.find((id) => {
      const unitName = entitiesImpactData?.[id]?.[questionIndex]?.unitName
      return unitName && unitName !== NOT_FOUND
    })

    if (entityIdWithDataForQuestion) {
      return (
        entitiesImpactData?.[entityIdWithDataForQuestion]?.[questionIndex]?.unitName || NOT_FOUND
      )
    }
  }

  return initialUnitName
}

const getLabelledIds = ({
  portfolioIds,
  entityIds,
}: {
  portfolioIds: string[]
  entityIds: string[]
}) => {
  return {
    labelledPortfolioIds: portfolioIds.map((id) => ['portfolio', id] as const),
    labelledEntityIds: entityIds.map((id) => ['entity', id] as const),
  }
}

export const getPerformanceForQuestion = ({
  portfolioIds,
  portfoliosImpactData,
  entityIds,
  entitiesImpactData,
  questionIndex,
  activeView,
}: {
  portfolioIds: string[]
  portfoliosImpactData: ImpactDataForCompare | undefined
  entityIds: string[]
  entitiesImpactData: ImpactDataForCompare | undefined
  questionIndex: number
  activeView: string | undefined
}): Record<'portfolios' | 'entities', Record<string, ComparePerformance>> => {
  if (portfolioIds.length === 0 && entityIds.length === 0) {
    return {
      portfolios: {},
      entities: {},
    }
  }

  if (portfolioIds.length === 1 && !entityIds.length) {
    return {
      portfolios: {
        [portfolioIds[0] as string]: 'n/a',
      },
      entities: {},
    }
  }

  if (!portfolioIds.length && entityIds.length === 1) {
    return {
      portfolios: {},
      entities: {
        [entityIds[0] as string]: 'n/a',
      },
    }
  }

  const goal =
    portfoliosImpactData?.[portfolioIds[0] as string]?.[questionIndex]?.goal ||
    entitiesImpactData?.[entityIds[0] as string]?.[questionIndex]?.goal
  const isPositive = goal === 'increase'
  const isNegative = goal === 'decrease'
  const isNeutral = goal === 'neutral'

  const { labelledPortfolioIds, labelledEntityIds } = getLabelledIds({
    portfolioIds,
    entityIds,
  })

  const sortedIdsByQuestionPerformance = [...labelledPortfolioIds, ...labelledEntityIds].sort(
    (a, b) => {
      const dataForQuestionA =
        a[0] === 'portfolio'
          ? portfoliosImpactData?.[a[1] as string]?.[questionIndex]
          : entitiesImpactData?.[a[1] as string]?.[questionIndex]
      const dataForQuestionB =
        b[0] === 'portfolio'
          ? portfoliosImpactData?.[b[1] as string]?.[questionIndex]
          : entitiesImpactData?.[b[1] as string]?.[questionIndex]

      const intensityValueA =
        (activeView === 'revenue'
          ? dataForQuestionA?.revenueIntensity
          : dataForQuestionA?.intensity) || 0
      const intensityValueB =
        (activeView === 'revenue'
          ? dataForQuestionB?.revenueIntensity
          : dataForQuestionB?.intensity) || 0

      return intensityValueB - intensityValueA
    },
  )

  const [highestPerformerType, highestPerformerId] =
    sortedIdsByQuestionPerformance.find((idTypePair) => {
      if (idTypePair[0] === 'entity') {
        return activeView === 'revenue'
          ? entitiesImpactData?.[idTypePair[1]]?.[questionIndex]?.revenueIntensity !== undefined
          : entitiesImpactData?.[idTypePair[1]]?.[questionIndex]?.intensity !== undefined
      } else {
        return activeView === 'revenue'
          ? portfoliosImpactData?.[idTypePair[1]]?.[questionIndex]?.revenueIntensity !== undefined
          : portfoliosImpactData?.[idTypePair[1]]?.[questionIndex]?.intensity !== undefined
      }
    }) || []

  const [lowestPerformerType, lowestPerformerId] =
    [...sortedIdsByQuestionPerformance].reverse().find((idTypePair) => {
      if (idTypePair[0] === 'entity') {
        return activeView === 'revenue'
          ? entitiesImpactData?.[idTypePair[1]]?.[questionIndex]?.revenueIntensity !== undefined
          : entitiesImpactData?.[idTypePair[1]]?.[questionIndex]?.intensity !== undefined
      } else {
        return activeView === 'revenue'
          ? portfoliosImpactData?.[idTypePair[1]]?.[questionIndex]?.revenueIntensity !== undefined
          : portfoliosImpactData?.[idTypePair[1]]?.[questionIndex]?.intensity !== undefined
      }
    }) || []

  const lowestPerformerData =
    lowestPerformerType === 'portfolio'
      ? portfoliosImpactData?.[lowestPerformerId]
      : lowestPerformerId
        ? entitiesImpactData?.[lowestPerformerId]
        : undefined
  const highestPerformerData =
    highestPerformerType === 'portfolio'
      ? portfoliosImpactData?.[highestPerformerId]
      : highestPerformerId
        ? entitiesImpactData?.[highestPerformerId]
        : undefined

  const allValuesAreTheSame =
    activeView === 'revenue'
      ? lowestPerformerData?.[questionIndex]?.revenueIntensity ===
        highestPerformerData?.[questionIndex]?.revenueIntensity
      : lowestPerformerData?.[questionIndex]?.intensity ===
        highestPerformerData?.[questionIndex]?.intensity

  if (isNeutral || allValuesAreTheSame) {
    return sortedIdsByQuestionPerformance.reduce(
      (acc, idTypePair) => {
        if (idTypePair[0] === 'entity') {
          return {
            ...acc,
            entities: {
              ...acc.entities,
              [idTypePair[1]]: null,
            },
          }
        }

        return {
          ...acc,
          portfolios: {
            ...acc.portfolios,
            [idTypePair[1]]: null,
          },
        }
      },
      { portfolios: {}, entities: {} },
    )
  }

  return sortedIdsByQuestionPerformance.reduce<
    Record<'portfolios' | 'entities', Record<string, ComparePerformance>>
  >(
    (acc, idTypePair) => {
      const matchesHighestPerformer =
        idTypePair[0] === highestPerformerType && idTypePair[1] === highestPerformerId
      const matchesLowestPerformer =
        idTypePair[0] === lowestPerformerType && idTypePair[1] === lowestPerformerId

      const isBest =
        (isPositive && matchesHighestPerformer) || (isNegative && matchesLowestPerformer)
          ? 'best'
          : null
      const isWorst =
        (isPositive && matchesLowestPerformer) || (isNegative && matchesHighestPerformer)
          ? 'worst'
          : null
      const isNd =
        idTypePair[0] === 'portfolio'
          ? portfoliosImpactData?.[idTypePair[1]]?.[questionIndex]?.isNd
          : entitiesImpactData?.[idTypePair[1]]?.[questionIndex]?.isNd

      if (idTypePair[0] === 'entity') {
        return {
          ...acc,
          entities: {
            ...acc.entities,
            [idTypePair[1]]: isNd ? null : isBest || isWorst,
          },
        }
      }

      return {
        ...acc,
        portfolios: {
          ...acc.portfolios,
          [idTypePair[1]]: isNd ? null : isBest || isWorst,
        },
      }
    },
    { portfolios: {}, entities: {} },
  )
}

const getFilteredData = ({
  portfoliosImpactData,
  entitiesImpactData,
  portfolioIds,
  entityIds,
  showNotApplicable,
  showNotDisclosed,
}: {
  portfoliosImpactData: ImpactDataForCompare | undefined
  entitiesImpactData: ImpactDataForCompare | undefined
  portfolioIds: string[]
  entityIds: string[]
  showNotApplicable: boolean
  showNotDisclosed: boolean
}): {
  portfolios: ImpactDataForCompare | undefined
  entities: ImpactDataForCompare | undefined
} => {
  const hasDataForAtLeastOnePortfolio = portfoliosImpactData && portfolioIds.length > 0
  const hasDataForAtLeastOneEntity = entitiesImpactData && entityIds.length > 0

  if (!hasDataForAtLeastOnePortfolio && !hasDataForAtLeastOneEntity) {
    return {
      portfolios: undefined,
      entities: undefined,
    }
  }

  const dataToLoop = hasDataForAtLeastOnePortfolio
    ? portfoliosImpactData?.[portfolioIds[0] as string] || []
    : entitiesImpactData?.[entityIds[0] as string] || []

  const { labelledPortfolioIds, labelledEntityIds } = getLabelledIds({
    portfolioIds,
    entityIds,
  })

  const idTypePairs = [...labelledPortfolioIds, ...labelledEntityIds]

  return dataToLoop.reduce(
    (acc, _, questionIdx) => {
      const includeQuestion = idTypePairs.some((idTypePair) => {
        const matchingQuestion = (
          idTypePair[0] === 'portfolio' ? portfoliosImpactData : entitiesImpactData
        )?.[idTypePair[1] as string]?.[questionIdx]

        if (matchingQuestion?.unitName !== NOT_FOUND) {
          return true
        }

        if (!matchingQuestion?.isNd && showNotApplicable) {
          return true
        }

        if (matchingQuestion?.isNd && showNotDisclosed) {
          return true
        }

        return false
      })

      return includeQuestion
        ? idTypePairs.reduce(
            (innerAcc, idTypePair) => {
              const newValue =
                idTypePair[0] === 'portfolio'
                  ? portfoliosImpactData?.[idTypePair[1]]?.[questionIdx]
                  : entitiesImpactData?.[idTypePair[1]]?.[questionIdx]

              if (!newValue) {
                return {
                  portfolios: innerAcc?.portfolios,
                  entities: innerAcc?.entities,
                }
              }

              if (idTypePair[0] === 'portfolio') {
                return {
                  portfolios: {
                    ...(innerAcc?.portfolios ?? {}),
                    [idTypePair[1]]: [...(acc?.portfolios?.[idTypePair[1]] || []), newValue],
                  },
                  entities: innerAcc?.entities,
                }
              }

              return {
                portfolios: innerAcc?.portfolios,
                entities: {
                  ...(innerAcc?.entities ?? {}),
                  [idTypePair[1]]: [...(acc?.entities?.[idTypePair[1]] || []), newValue],
                },
              }
            },

            acc,
          )
        : acc
    },
    { portfolios: undefined, entities: undefined } as {
      portfolios: ImpactDataForCompare | undefined
      entities: ImpactDataForCompare | undefined
    },
  )
}

export const getImpactData = ({
  portfoliosImpactData,
  entitiesImpactData,
  showNotDisclosed,
  showNotApplicable,
  activeView,
}: {
  portfoliosImpactData: ImpactDataForCompare | undefined
  entitiesImpactData: ImpactDataForCompare | undefined
  showNotDisclosed: boolean
  showNotApplicable: boolean
  activeView: string | undefined
}): CompareImpactData[] => {
  if (!portfoliosImpactData && !entitiesImpactData) {
    return []
  }

  const portfolioIds = portfoliosImpactData ? Object.keys(portfoliosImpactData) : []
  const entityIds = entitiesImpactData ? Object.keys(entitiesImpactData) : []

  if (!portfolioIds.length && !entityIds.length) {
    return []
  }

  const { portfolios: filteredPortfoliosImpactData, entities: filteredEntitiesImpactData } =
    getFilteredData({
      portfoliosImpactData,
      entitiesImpactData,
      portfolioIds,
      entityIds,
      showNotApplicable,
      showNotDisclosed,
    })

  // We only need to loop over the impact data for one portfolio, because all
  // portfolios and entities should have the same number of questions in the same order.
  const data = (
    (portfolioIds.length
      ? filteredPortfoliosImpactData?.[portfolioIds[0] as string]
      : filteredEntitiesImpactData?.[entityIds[0] as string]) || []
  ).map((item, questionIndex) => {
    const unitName = getUnitName({
      initialUnitName: item.unitName,
      portfolioIds,
      portfoliosImpactData: filteredPortfoliosImpactData,
      entityIds,
      entitiesImpactData: filteredEntitiesImpactData,
      questionIndex,
    })

    const performanceForQuestion = getPerformanceForQuestion({
      portfolioIds,
      portfoliosImpactData: filteredPortfoliosImpactData,
      entityIds,
      entitiesImpactData: filteredEntitiesImpactData,
      questionIndex,
      activeView,
    })

    return {
      questionId: item.questionId as number,
      goal: item.goal,
      name: item.displayName,
      unit: unitName,
      displayThemes: item.displayThemes,
      displayPriority: item.displayPriority ?? null,
      impactLevel: item.impactLevel,
      portfolios: portfolioIds.reduce<CompareImpactData['portfolios']>((acc, portfolioId) => {
        const matchingQuestion = filteredPortfoliosImpactData?.[portfolioId]?.[questionIndex]

        return {
          ...acc,
          [portfolioId]: {
            value: matchingQuestion?.value,
            intensity:
              activeView === 'revenue'
                ? matchingQuestion?.revenueIntensity
                : matchingQuestion?.intensity,
            yearOnYear: matchingQuestion?.yearOnYear,
            performance: performanceForQuestion.portfolios[portfolioId],
            isNd: matchingQuestion?.isNd,
          },
        }
      }, {}),
      companies: entityIds.reduce<CompareImpactData['companies']>((acc, entityId) => {
        const matchingQuestion = filteredEntitiesImpactData?.[entityId as string]?.[questionIndex]

        return {
          ...acc,
          [entityId]: {
            value: matchingQuestion?.value,
            intensity:
              activeView === 'revenue'
                ? matchingQuestion?.revenueIntensity
                : matchingQuestion?.intensity,
            yearOnYear: matchingQuestion?.yearOnYear,
            performance: performanceForQuestion.entities[entityId],
            factoidOrigin: matchingQuestion?.factoidOrigin,
            isNd: matchingQuestion?.isNd,
          },
        }
      }, {}),
    }
  })

  return data
}
